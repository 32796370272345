import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { GroupsSelectionProvider } from "~ui-components/contexts/group-selection";
import { Group } from "~ui-components/types/group";

import { UserEditForm, UserEditFormProps } from "./user-edit-form";

interface UserEditProps {
	form: UserEditFormProps["form"];
	groups: Group[];
}

export function UserEdit(props: UserEditProps): React.JSX.Element {
	const { form, groups } = props;

	return (
		<DashboardContentLayout pageTitle="Edit user">
			<GroupsSelectionProvider groups={groups}>
				<UserEditForm
					form={form}
					rows={groups}
				/>
			</GroupsSelectionProvider>
		</DashboardContentLayout>
	);
}

export * from "./user-edit-form";
