import {
	Alert,
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	FormControl,
	FormHelperText,
	InputLabel,
	LinearProgress,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { DropboxLogo } from "@phosphor-icons/react/dist/ssr";
import { ArrowsClockwise } from "@phosphor-icons/react/dist/ssr/ArrowsClockwise";
import { useFormik } from "formik";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import {
	Dataset,
	DATASOURCE_TYPE,
} from "~ui-components/types/__generated/gql/graphql";
import { getExtensionFromFileName } from "~utils/functions/getExtensionFromFileName";
import { getRequiredFileExtensionsFromDatasourceType } from "../data-import";

export const DatasetSyncSchema = Yup.object({
	dataFormat: Yup.mixed<DATASOURCE_TYPE>().required("Required"),
	files: Yup.array<File>().required("Required"),
});

export type DatasetSyncFormValues = Yup.InferType<typeof DatasetSyncSchema>;

export interface DatasetSyncProps {
	dataset: Dataset;
	form: ReturnType<typeof useFormik<DatasetSyncFormValues>>;
	onConnectDropbox: () => void;
	onDisconnectDropbox: () => void;
	onOpenDropboxFileDialog: () => void;
	isDropboxConnected: boolean;
	isSyncActive: boolean;
	onActivateSync: () => void;
}

export const DATASOURCE_TYPE_OPTIONS: Record<
	Exclude<
		DATASOURCE_TYPE,
		| DATASOURCE_TYPE.GEOJSON
		| DATASOURCE_TYPE.GEOTIFF
		| DATASOURCE_TYPE.WMS
		| DATASOURCE_TYPE.GEO_DATABASE
		| DATASOURCE_TYPE.KML
	>,
	string
> = {
	[DATASOURCE_TYPE.CSV]: "CSV",
	[DATASOURCE_TYPE.SHAPEFILE]: "Shapefile",
	[DATASOURCE_TYPE.TAB]: "Tab",
};

export const DatasetSync: FC<DatasetSyncProps> = ({
	dataset,
	form,
	onConnectDropbox,
	onDisconnectDropbox,
	onOpenDropboxFileDialog,
	isDropboxConnected,
	isSyncActive,
	onActivateSync,
}) => {
	const requiredFileExtensions = getRequiredFileExtensionsFromDatasourceType(
		form.values.dataFormat,
	);
	const areFilesSelected = form.values.files.length > 0;
	const [uploadProgresses, setUploadProgresses] = useState<
		Record<string, number>
	>({});
	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		setUploadProgresses({});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.values.dataFormat]);

	// TODO: remove this code, leave here for now for functioning mock ui
	useEffect(() => {
		if (form.values.files.length > 0) {
			intervalRef.current = setInterval(() => {
				setUploadProgresses((prev) => {
					const newState = Object.fromEntries(
						form.values.files.map((file) => [
							getExtensionFromFileName(file.name),
							prev[getExtensionFromFileName(file.name)] === 100
								? 100
								: (prev[getExtensionFromFileName(file.name)] ?? 0) + 1,
						]),
					);

					return newState;
				});
			}, 50);
		} else {
			setUploadProgresses({});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.values.files]);

	useEffect(() => {
		if (
			Object.keys(uploadProgresses).length &&
			Object.keys(uploadProgresses).every(
				(key) => uploadProgresses[key] === 100,
			)
		) {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
			onActivateSync();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadProgresses]);

	return (
		<DashboardContentLayout
			pageTitle={
				<>
					{dataset.name}&nbsp;
					{isSyncActive && (
						<Chip
							icon={<DropboxLogo fontSize="var(--Icon-fontSize)" />}
							label="Sync active"
							color="success"
							variant="outlined"
						/>
					)}
				</>
			}
			actionButtonProps={
				isDropboxConnected
					? isSyncActive
						? {
								variant: "contained",
								children: "Update file location",
								onClick: onOpenDropboxFileDialog,
							}
						: undefined
					: {
							variant: "contained",
							children: "Connect Dropbox",
							onClick: onConnectDropbox,
						}
			}>
			<Stack
				direction={{ xs: "column", md: "row" }}
				spacing={4}
				sx={{ position: "relative" }}>
				<Box sx={{ flex: "1 1 auto", minWidth: 0 }}>
					<form>
						<Card>
							<CardHeader
								avatar={
									<Avatar>
										<ArrowsClockwise fontSize="var(--Icon-fontSize)" />
									</Avatar>
								}
								title="Update maps automatically from data in Dropbox"
								subheader="Mango Data Sync automatically pulls the latest updates from your Dropbox into Mango, ensuring all maps using the data stay current without manual intervention."
							/>

							<CardContent>
								<Stack spacing={3}>
									{isDropboxConnected && (
										<Stack
											direction="row"
											spacing={2}
											sx={{ alignItems: "center" }}>
											<Avatar
												src="/new-mangomap-assets/avatar.png"
												sx={{
													bgcolor: "var(--mui-palette-background-paper)",
													boxShadow: "var(--mui-shadows-8)",
													color: "var(--mui-palette-text-primary)",
												}}
											/>
											<Box>
												<Typography variant="subtitle2">John Doe</Typography>
												<Typography
													color="text.secondary"
													variant="caption">
													john-doe@mapstack.io
												</Typography>
											</Box>
											<Button
												color="inherit"
												endIcon={<DropboxLogo />}
												size="small"
												variant="outlined"
												onClick={onDisconnectDropbox}>
												Disconnect
											</Button>
										</Stack>
									)}

									{!isSyncActive && (
										<FormControl fullWidth>
											<InputLabel>Data format</InputLabel>
											<Stack
												direction="row"
												gap={3}>
												<TextField
													select
													{...form.getFieldProps("dataFormat")}
													error={!!form.errors.dataFormat}
													helperText={form.errors.dataFormat}
													sx={{ width: "150px" }}>
													{Object.keys(DATASOURCE_TYPE_OPTIONS).map((key) => (
														<MenuItem
															key={key}
															value={key}>
															{DATASOURCE_TYPE_OPTIONS[key]}
														</MenuItem>
													))}
												</TextField>
												<Button
													disabled={!isDropboxConnected}
													variant="contained"
													sx={{ whiteSpace: "nowrap" }}
													onClick={onOpenDropboxFileDialog}>
													Select files from Dropbox
												</Button>
											</Stack>
										</FormControl>
									)}

									<Stack spacing={3}>
										<Stack>
											<InputLabel>
												{isSyncActive ? "Files tracked" : "Required files"}
											</InputLabel>
											<Divider />
											{!!form.errors.files && (
												<FormHelperText error>
													{form.errors.files.toString()}
												</FormHelperText>
											)}
										</Stack>

										{requiredFileExtensions.map((ext, index) => {
											const matchingFile = form.values.files.find((file) =>
												file.name.endsWith(`.${ext}`),
											);

											return (
												<Fragment key={ext}>
													<Stack
														direction="row"
														spacing={3}>
														<Typography
															flex={1}
															color="text.secondary"
															variant="body2">
															{`.${ext}`}
														</Typography>
														{!!areFilesSelected && (
															<Typography
																flex={2}
																fontWeight={600}
																variant="body2"
																sx={{
																	maxWidth: "100%",
																	whiteSpace: "nowrap",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																}}>
																{matchingFile ? matchingFile.name : ""}
															</Typography>
														)}
														{!isSyncActive && (
															<Box flex={3}>
																{!!areFilesSelected &&
																	(matchingFile ? (
																		<LinearProgress
																			variant={
																				uploadProgresses[ext]
																					? "determinate"
																					: "indeterminate"
																			}
																			color={
																				(uploadProgresses[ext] ?? 0) < 100
																					? "primary"
																					: "success"
																			}
																			value={uploadProgresses[ext] ?? 0}
																		/>
																	) : (
																		<Alert
																			severity="error"
																			action={
																				<Button
																					color="error"
																					size="small"
																					onClick={() => {
																						onOpenDropboxFileDialog();
																					}}>
																					Upload
																				</Button>
																			}>
																			File missing
																		</Alert>
																	))}
															</Box>
														)}
													</Stack>
													{index !== requiredFileExtensions.length - 1 && (
														<Divider />
													)}
												</Fragment>
											);
										})}
									</Stack>
								</Stack>
							</CardContent>
						</Card>
					</form>
				</Box>
			</Stack>
		</DashboardContentLayout>
	);
};
