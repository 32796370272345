import { useMutation } from "@tanstack/react-query";
import { SignUpFormValues, SignUpRemoteFormValues } from "ui-components";
import { apiInstance } from "utils";

export const useSignUp = () => {
	return useMutation({
		mutationKey: ["signUp"],
		mutationFn: (values: SignUpFormValues & SignUpRemoteFormValues) => {
			const [firstName, lastName] = values.name.split(" ");
			const postForm = new FormData();
			postForm.append("user[first_name]", firstName!);
			postForm.append("user[last_name]", lastName!);
			postForm.append("user[user_name]", values.companyName);
			postForm.append("user[portal_url]", values.portalUrl);
			postForm.append("user[phone]", "855123456789");
			postForm.append("user[email]", values.email);
			postForm.append("user[password]", values.password);
			return apiInstance.post("/clients/create_account.json", postForm);
		},
	});
};
