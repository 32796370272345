import { graphierQL } from "~utils/api/graphierQL";
import {
	GetMapQuery,
	GetMapQueryVariables,
} from "~utils/types/__generated/gql/graphql";

const getMapQuery = /* GraphQL */ `
	fragment SymbolStyleFragment on SymbolStyle {
		name
		isGraphic
		basic {
			size
			rotation
			symbol
			radius
			opacity
		}
		fill {
			width
			fillType
			style
			color
			opacity
			isDisabled
		}
		stroke {
			style
			color
			width
			opacity
			isDisabled
		}
	}

	fragment SymbolValueFragment on SymbolValue {
		color
		value
		label
		count
		symbol {
			...SymbolStyleFragment
		}
		id
		range
		legendVisibility
		min
		max
	}

	query GetMap($id: ID!) {
		getMapById(id: $id) {
			id
			createdAt
			updatedAt
			name
			description
			userId
			tools {
				type
			}
			layerGroupCollection {
				total
				items {
					id
					createdAt
					updatedAt
					mapId
					visible
					layersOrder
					layerCollection {
						total
						items {
							id
							createdAt
							updatedAt
							name
							description
							minZoom
							maxZoom
							visible
							datasetId
							layerGroupId
							dataType
							styleType
							labelsProps {
								attribute
								font
								fontSize
								fontStyle
								fontColour
								fontOpacity
								haloColour
								haloOpacity
								haloSize
							}
							lineSimpleStyle {
								symbolStyle {
									...SymbolStyleFragment
								}
							}
							lineCategoryStyle {
								colorRamp
								symbolStyle {
									...SymbolStyleFragment
								}
								valueField
								symbolValues {
									...SymbolValueFragment
								}
							}
							polygonCategoryStyle {
								colorRamp
								symbolStyle {
									...SymbolStyleFragment
								}
								valueField
								symbolValues {
									...SymbolValueFragment
								}
							}
							lineQuantityStyle {
								valueField
								legendType
								classNumber
								colorRamp
								classification
								symbolStyle {
									...SymbolStyleFragment
								}
								symbolValues {
									...SymbolValueFragment
								}
								classbreaks
							}
							pointSimpleStyle {
								symbolStyle {
									...SymbolStyleFragment
								}
							}
							pointCategoryStyle {
								colorRamp
								symbolStyle {
									...SymbolStyleFragment
								}
								valueField
								symbolValues {
									...SymbolValueFragment
								}
							}
							pointQuantityStyle {
								valueField
								legendType
								classNumber
								colorRamp
								classification
								symbolStyle {
									...SymbolStyleFragment
								}
								symbolValues {
									...SymbolValueFragment
								}
								classbreaks
							}
							heatMapStyle {
								basic {
									radius
									opacity
								}
							}
							polygonSimpleStyle {
								symbolStyle {
									...SymbolStyleFragment
								}
							}
							bubbleStyle {
								valueField
								classNumber
								classification
								radius {
									min
									max
								}
								symbolStyle {
									...SymbolStyleFragment
								}
								symbolValues {
									...SymbolValueFragment
								}
							}
							polygonQuantityStyle {
								valueField
								legendType
								classNumber
								colorRamp
								classification
								symbolStyle {
									...SymbolStyleFragment
								}
								symbolValues {
									...SymbolValueFragment
								}
								classbreaks
							}

							_dataset {
								id
								createdAt
								updatedAt
								name
								description
								userId
								type
								datasourceType
								sourceDatasetId
								size
								mapsCount
								legendUrl
								getCapabilitiesUrl
								isPrivate
								boundingBoxes {
									north
									south
									east
									west
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const getMapById = async (id: string) => {
	const { getMapById: result } = await graphierQL<
		GetMapQuery,
		GetMapQueryVariables
	>({
		query: getMapQuery,
		variables: {
			id,
		},
	});
	return result;
};
