import * as React from "react";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react/dist/ssr/MagnifyingGlass";

import { Option } from "~ui-components/components/atoms/option";

export interface Filters {
	query?: string;
}

type SortDir = "asc" | "desc";

export interface FiltersProps {
	filters: Filters;
	sortDir: SortDir;
}

export const AddLayerPanelFilters: React.FC<FiltersProps> = ({
	sortDir = "desc",
}) => {
	return (
		<Card>
			<Stack
				direction="row"
				spacing={4}
				sx={{ alignItems: "center", flexWrap: "wrap", p: 2 }}>
				<OutlinedInput
					name="name"
					placeholder="Search"
					startAdornment={
						<InputAdornment position="start">
							<MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
						</InputAdornment>
					}
					sx={{ flex: "1 1 auto" }}
				/>
				<Select
					name="sort"
					sx={{ maxWidth: "100%", width: "120px" }}
					value={sortDir}>
					<Option value="desc">Newest</Option>
					<Option value="asc">Oldest</Option>
				</Select>
			</Stack>
		</Card>
	);
};
