const slug = (func) => {
	return (...args) => {
		const modifiedArgs = args.map((arg) => encodeURIComponent(arg));
		return func(...modifiedArgs);
	};
};

const pret = (val: string) => val.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-");

export const routes = {
	accountName: {
		maps: {
			root: "/[account-name]/maps",
			mapId: {
				mapName: {
					root: "/[account-name]/maps/[map-id]/[map-name]",
					layers: {
						root: "/[account-name]/maps/[map-id]/[map-name]/layers",
						radio: "/[account-name]/maps/[map-id]/[map-name]/layers/radio",
						disabled:
							"/[account-name]/maps/[map-id]/[map-name]/layers/disabled",
						layerId:
							"/[account-name]/maps/[map-id]/[map-name]/layers/[layer-id]",
					},
					tools: "/[account-name]/maps/[map-id]/[map-name]/tools",
					access: "/[account-name]/maps/[map-id]/[map-name]/access",
					share: "/[account-name]/maps/[map-id]/[map-name]/share",
					settings: "/[account-name]/maps/[map-id]/[map-name]/settings",
				},
			},
		},
		data: {
			root: "/[account-name]/data",
			datasetId: {
				root: "/[account-name]/data/[dataset-id]",
				settings: "/[account-name]/data/[dataset-id]/settings",
				update: "/[account-name]/data/[dataset-id]/update",
				sync: "/[account-name]/data/[dataset-id]/sync",
				access: "/[account-name]/data/[dataset-id]/access",
			},
		},
		users: {
			root: "/[account-name]/users",
		},
		groups: {
			root: "/[account-name]/groups",
		},
		branding: {
			root: "/[account-name]/branding",
			localisation: "/[account-name]/branding/localisation",
			domainAndWhiteLabel: "/[account-name]/branding/domain-and-white-label",
		},
		support: {
			root: "/[account-name]/support",
		},
		settings: {
			root: "/[account-name]/settings",
			profile: "/[account-name]/settings/profile",
			password: "/[account-name]/settings/password",
			checkout: "/[account-name]/settings/checkout",
			cancelSubscription: "/[account-name]/settings/cancel-subscription",
			editBilling: "/[account-name]/settings/edit-billing",
			editSubscription: "/[account-name]/settings/edit-subscription",
			subscriptionConfirmation:
				"/[account-name]/settings/subscription-confirmation",
		},
	},
};

export const paths = {
	home: "/",
	privacy: "/privacy",
	terms: "/terms",
	forgotPassword: "/forgot-password",
	signIn: "/sign-in",
	signUp: "/sign-up",
	signUpVerification: "/sign-up-verification",
	accountName: {
		maps: {
			root: slug((accountName) => `/${accountName}/maps`),
			new: slug(
				(accountName) => `/${accountName}/maps/new-map-id/new-map-name`,
			),
			mapId: {
				mapName: {
					root: slug((accountName, mapId, mapName, query = "") => {
						const queryText = encodeURI(query ? `?${query}` : "");
						return `/${accountName}/maps/${mapId}/${pret(mapName)}${queryText}`;
					}),
					layers: {
						root: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/layers`,
						),
						layerId: {
							details: slug(
								(accountName, mapId, mapName, layerId) =>
									`/${accountName}/maps/${mapId}/${pret(mapName)}/layers/${layerId}/details`,
							),
							style: slug(
								(accountName, mapId, mapName, layerId) =>
									`/${accountName}/maps/${mapId}/${pret(mapName)}/layers/${layerId}/style`,
							),
							label: slug(
								(accountName, mapId, mapName, layerId) =>
									`/${accountName}/maps/${mapId}/${pret(mapName)}/layers/${layerId}/label`,
							),
							popup: slug(
								(accountName, mapId, mapName, layerId) =>
									`/${accountName}/maps/${mapId}/${pret(mapName)}/layers/${layerId}/popup`,
							),
							attributeFormatting: slug(
								(accountName, mapId, mapName, layerId) =>
									`/${accountName}/maps/${mapId}/${pret(mapName)}/layers/${layerId}/attribute-formatting`,
							),
						},
						radio: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/layers/radio`,
						),
						disabled: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/layers/disabled`,
						),
					},
					addLayer: slug(
						(accountName, mapId, mapName, layerGroupId) =>
							`/${accountName}/maps/${mapId}/${pret(mapName)}/add-layer?layerGroupId=${layerGroupId}`,
					),
					tools: {
						root: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools`,
						),
						legend: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools/legend`,
						),
						search: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools/search`,
						),
						advancedSearch: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools/advanced-search`,
						),
						query: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools/query`,
						),
						proximityAnalysis: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools/proximity-analysis`,
						),
						informationSidebar: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools/information-sidebar`,
						),
						print: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools/print`,
						),
						measure: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools/measure`,
						),
						copyright: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools/copyright`,
						),
						mouseHighlight: slug(
							(accountName, mapId, mapName) =>
								`/${accountName}/maps/${mapId}/${pret(mapName)}/tools/mouse-highlight`,
						),
					},
					access: slug(
						(accountName, mapId, mapName) =>
							`/${accountName}/maps/${mapId}/${pret(mapName)}/access`,
					),
					share: slug(
						(accountName, mapId, mapName) =>
							`/${accountName}/maps/${mapId}/${pret(mapName)}/share`,
					),
					settings: slug(
						(accountName, mapId, mapName) =>
							`/${accountName}/maps/${mapId}/${pret(mapName)}/settings`,
					),
				},
			},
			printPreview: slug(
				(accountName, mapId) =>
					`/${accountName}/maps/${mapId}?print-preview=true`,
			),
		},
		data: {
			root: slug((accountName) => `/${accountName}/data`),
			import: slug((accountName: string, layerGroupId?: string) =>
				`/${accountName}/data/import`.concat(
					layerGroupId ? `?layerGroupId=${layerGroupId}` : "",
				),
			),
			datasetId: {
				root: slug(
					(accountName, datasetId) => `/${accountName}/data/${datasetId}`,
				),
				access: slug(
					(accountName, datasetId) =>
						`/${accountName}/data/${datasetId}/access`,
				),
				sync: slug(
					(accountName, datasetId) => `/${accountName}/data/${datasetId}/sync`,
				),
				settings: slug(
					(accountName, datasetId) =>
						`/${accountName}/data/${datasetId}/settings`,
				),
				update: slug(
					(accountName, datasetId) =>
						`/${accountName}/data/${datasetId}/update`,
				),
			},
		},
		users: {
			root: slug((accountName) => `/${accountName}/users`),
			invite: slug((accountName) => `/${accountName}/users/invite`),
			edit: slug(
				(accountName, userId) => `/${accountName}/users/${userId}/edit`,
			),
		},
		groups: {
			root: slug((accountName) => `/${accountName}/groups`),
			create: slug((accountName) => `/${accountName}/groups/create`),
			edit: slug(
				(accountName, groupId) => `/${accountName}/groups/${groupId}/edit`,
			),
		},
		branding: {
			root: slug((accountName) => `/${accountName}/branding`),
			portal: slug((accountName) => `/${accountName}/branding/portal`),
			theme: slug((accountName) => `/${accountName}/branding/theme`),
			localisation: slug(
				(accountName) => `/${accountName}/branding/localisation`,
			),
			domainAndWhiteLabel: slug(
				(accountName) => `/${accountName}/branding/domain-and-white-label`,
			),
			analytics: slug((accountName) => `/${accountName}/branding/analytics`),
		},
		support: slug((accountName) => `/${accountName}/support`),
		settings: {
			root: slug((accountName) => `/${accountName}/settings`),
			checkout: slug((accountName) => `/${accountName}/settings/checkout`),
			cancelSubscription: slug(
				(accountName) => `/${accountName}/settings/cancel-subscription`,
			),
			editBilling: slug(
				(accountName) => `/${accountName}/settings/edit-billing`,
			),
			editSubscription: slug(
				(accountName) => `/${accountName}/settings/edit-subscription`,
			),
			subscriptionConfirmation: slug(
				(accountName) => `/${accountName}/settings/subscription-confirmation`,
			),
			profile: slug((accountName) => `/${accountName}/settings/profile`),
			password: slug((accountName) => `/${accountName}/settings/password`),
		},
	},
} as const;
