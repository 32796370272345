import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import { CreditCard } from "@phosphor-icons/react/dist/ssr/CreditCard";
import { FC } from "react";

import { User } from "~ui-components/types/__generated/gql/graphql";
import {
	Summary,
	usePlanSummary,
	USERS_SUMMARY,
} from "../subscription-summary";

export const PaidUserAccount: FC<{ user: User }> = () => {
	const planSummary = usePlanSummary();

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<CreditCard fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Subscription summary"
			/>
			<CardContent>
				<Stack spacing={3}>
					<Summary items={planSummary} />
					<Summary items={USERS_SUMMARY} />
				</Stack>
			</CardContent>
		</Card>
	);
};
