import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { GroupsSelectionProvider } from "~ui-components/contexts/group-selection";
import { Group } from "~ui-components/types/group";

import { UserCreateForm, UserCreateFormProps } from "./user-create-form";

interface UserCreateProps {
	form: UserCreateFormProps["form"];
	groups: Group[];
}

export function UserCreate(props: UserCreateProps): React.JSX.Element {
	const { form, groups } = props;

	return (
		<DashboardContentLayout pageTitle="Create user">
			<GroupsSelectionProvider groups={groups}>
				<UserCreateForm
					form={form}
					rows={groups}
				/>
			</GroupsSelectionProvider>
		</DashboardContentLayout>
	);
}

export * from "./user-create-form";
