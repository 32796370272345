import * as React from "react";

import { useGetUser } from "~auth/queries/useGetUser";

export interface AuthContextValue {
	checkSession?: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextValue | undefined>(
	undefined,
);

export interface AuthProviderProps {
	children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const { refetch } = useGetUser();

	const checkSession = React.useCallback(async (): Promise<void> => {
		try {
			await refetch();
			// do something on success
		} catch (err) {
			console.error(err);
			// do something on error
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		void checkSession();

		// eslint-disable-next-line react-hooks/exhaustive-deps -- Expected
	}, []);

	return (
		<AuthContext.Provider value={{ checkSession }}>
			{children}
		</AuthContext.Provider>
	);
};

export const AuthConsumer = AuthContext.Consumer;
