import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { paths } from "config/paths";
import * as React from "react";
import { useRouter } from "next/router";

import { PortalActionCard } from "~ui-components/components/molecules/portal-action-card";

import { ItemCard } from "./items/item-card";

import { AddLayerPanelItemsViewProps } from ".";

export function AddLayerPanelItemsView(
	props: AddLayerPanelItemsViewProps,
): React.JSX.Element {
	const router = useRouter();

	const { isLoading, items, onItemClick } = props;

	return (
		<Box
			sx={{
				display: "grid",
				gap: 4,
				gridTemplateColumns: {
					xs: "1fr",
					sm: "repeat(2, 1fr)",
					md: "repeat(3, 1fr)",
				},
			}}>
			<PortalActionCard
				title="Import Data"
				href={paths.accountName.data.import(
					router.query["account-name"],
					router.query.layerGroupId,
				)}
			/>
			{isLoading && (
				<Box sx={{ p: 2 }}>
					<CircularProgress />
				</Box>
			)}
			{Array.from(items.values()).map((item, index) => (
				<ItemCard
					key={index}
					item={item}
					onClick={onItemClick}
				/>
			))}
		</Box>
	);
}
