import { GTMProvider, useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { usePathname, useSearchParams } from "next/navigation";
import * as React from "react";

export interface PageViewTrackerProps {
	children: React.ReactNode;
}

function PageViewTracker({
	children,
}: PageViewTrackerProps): React.JSX.Element {
	const dispatch = useGTMDispatch();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	React.useEffect(() => {
		dispatch({ event: "page_view", page: pathname });
	}, [dispatch, pathname, searchParams]);

	return <React.Fragment>{children}</React.Fragment>;
}

export interface AnalyticsProps {
	id?: string;
	children: React.ReactNode;
}

/**
 * This loads GTM and tracks the page views.
 *
 * If GTM ID is not configured, this will no track any event.
 */
export function AnalyticsProvider({
	id,
	children,
}: AnalyticsProps): React.JSX.Element {
	if (!id) {
		return <React.Fragment>{children}</React.Fragment>;
	}

	return (
		<GTMProvider state={{ id }}>
			<PageViewTracker>{children}</PageViewTracker>
		</GTMProvider>
	);
}
