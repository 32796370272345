import { InputAdornment } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useFormik } from "formik";
import { MuiColorInput } from "mui-color-input";

import { DataTable } from "~ui-components/components/atoms/data-table";
import {
	LineQuantityConfigs,
	PointQuantityConfigs,
	PolygonQuantityConfigs,
	SymbolValue,
} from "~ui-components/types/__generated/gql/graphql";

export interface CustomersTableProps {
	form:
		| ReturnType<typeof useFormik<LineQuantityConfigs>>
		| ReturnType<typeof useFormik<PolygonQuantityConfigs>>
		| ReturnType<typeof useFormik<PointQuantityConfigs>>;
}

export function QuantityTable({ form }: CustomersTableProps) {
	return (
		<DataTable<SymbolValue>
			rows={form.values.symbolValues}
			columns={[
				{
					formatter: (_, index) => {
						return (
							<MuiColorInput
								format="hex"
								sx={{ width: 200 }}
								value={
									form.values.symbolValues?.[index]?.symbol?.fill?.color ?? ""
								}
								onChange={(value) =>
									form.setFieldValue(
										`symbolValues[${index}].symbol.fill.color`,
										value,
									)
								}
							/>
						);
					},
					name: "Click to edit",
					width: "250px",
				},
				{
					formatter: (_, index) => (
						<OutlinedInput
							startAdornment={
								<InputAdornment position="start">
									{index === 0 ? ">=" : ">"}
								</InputAdornment>
							}
							{...form.getFieldProps(`symbolValues[${index}].min`)}
						/>
					),
					name: "Range",
					width: "250px",
				},
				{
					formatter: (_, index) => (
						<OutlinedInput
							{...form.getFieldProps(`symbolValues[${index}].label`)}
						/>
					),
					name: "Label",
					width: "250px",
				},
			]}
		/>
	);
}
