import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { MembersSelectionProvider } from "~ui-components/contexts/member-selection";
import { Member } from "~ui-components/types/member";

import { GroupCreateForm, GroupCreateFormProps } from "./group-create-form";

interface GroupCreateProps {
	form: GroupCreateFormProps["form"];
	members: Member[];
}

export function GroupCreate(props: GroupCreateProps): React.JSX.Element {
	const { form, members } = props;

	return (
		<DashboardContentLayout pageTitle="Create group">
			<MembersSelectionProvider members={members}>
				<GroupCreateForm
					form={form}
					rows={members}
				/>
			</MembersSelectionProvider>
		</DashboardContentLayout>
	);
}

export * from "./group-create-form";
