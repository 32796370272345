import GTM from "react-gtm-module";

const TagManager = {
	init(gtmId: string) {
		GTM.initialize({
			gtmId,
			events: {
				submit_contact_form: "submit_contact_form",
			},
		});
	},

	dataLayer: {
		submitContactForm() {
			GTM.dataLayer({
				dataLayer: {
					event: "form_submit",
				},
			});
		},
	},
};

export { TagManager };
