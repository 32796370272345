import { StackProps } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";

import { Option } from "~ui-components/components/atoms/option";
import { LineSimpleConfigs } from "~ui-components/types/__generated/gql/graphql";

type Props = StackProps & {
	form: ReturnType<typeof useFormik<LineSimpleConfigs>>;
	visualisation?: string;
};

export const LineSimpleStyle = (props: Props) => {
	const { form, visualisation } = props;
	const {
		values: { symbolStyle },
		handleChange,
		handleBlur,
		handleSubmit,
	} = form;

	console.log("symbolStyle", symbolStyle);

	if (visualisation === "simple") {
		return (
			<form onSubmit={handleSubmit}>
				<Stack spacing={3}>
					<InputLabel>Line and Casing</InputLabel>
					<FormControl>
						<InputLabel>Line colour</InputLabel>
						<MuiColorInput
							sx={{ width: 200 }}
							format="hex"
							value={symbolStyle?.fill?.color ?? ""}
							onChange={(value) =>
								form.setFieldValue("symbolStyle.fill.color", value)
							}
						/>
					</FormControl>
					<FormControl>
						<InputLabel>Line width</InputLabel>
						<OutlinedInput
							sx={{ width: 200 }}
							name="symbolStyle.fill.width"
							onChange={handleChange}
							onBlur={handleBlur}
							defaultValue={symbolStyle?.fill?.width}
							type="number"
						/>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Line style</InputLabel>
						<Select
							name="symbolStyle.fill.style"
							onChange={handleChange}
							defaultValue={symbolStyle?.fill?.style}>
							<Option
								key="none"
								value="none">
								None
							</Option>
							<Option
								key="solid"
								value="solid">
								Solid
							</Option>
							<Option
								key="dash"
								value="dash">
								Dash
							</Option>
							<Option
								key="dot"
								value="dot">
								Dot
							</Option>
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Line Opacity</InputLabel>
						<Select
							name="symbolStyle.fill.opacity"
							onChange={handleChange}
							defaultValue={symbolStyle?.fill?.opacity}
							type="number">
							{_.range(0, 11).map((value) => (
								<Option
									key={value}
									value={value / 10}>
									{value * 10}%
								</Option>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Casing colour</InputLabel>
						<MuiColorInput
							sx={{ width: 200 }}
							format="hex"
							value={symbolStyle?.stroke?.color ?? ""}
							onChange={(value) =>
								form.setFieldValue("symbolStyle.stroke.color", value)
							}
						/>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Casing opacity</InputLabel>
						<Select
							name="symbolStyle.stroke.opacity"
							onChange={handleChange}
							defaultValue={symbolStyle?.stroke?.opacity}
							type="number">
							{_.range(0, 11).map((value) => (
								<Option
									key={value}
									value={value / 10}>
									{value * 10}%
								</Option>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ width: 200 }}>
						<InputLabel>Casing style</InputLabel>
						<Select
							name="symbolStyle.stroke.style"
							onChange={handleChange}
							defaultValue={symbolStyle?.stroke?.style}>
							<Option
								key="none"
								value="none">
								None
							</Option>
							<Option
								key="solid"
								value="solid">
								Solid
							</Option>
							<Option
								key="dash"
								value="dash">
								Dash
							</Option>
							<Option
								key="dot"
								value="dot">
								Dot
							</Option>
						</Select>
					</FormControl>

					<FormControl sx={{ width: 200 }}>
						<InputLabel>Casing width</InputLabel>
						<OutlinedInput
							sx={{ width: 200 }}
							name="symbolStyle.stroke.width"
							onChange={handleChange}
							onBlur={handleBlur}
							defaultValue={symbolStyle?.stroke?.width}
							type="number"
						/>
					</FormControl>
				</Stack>
				<CardActions sx={{ justifyContent: "flex-end", p: 0, mt: 2 }}>
					<Button
						color="secondary"
						type="reset">
						Cancel
					</Button>
					<Button
						variant="contained"
						type="submit">
						Save changes
					</Button>
				</CardActions>
			</form>
		);
	}
	return null;
};
