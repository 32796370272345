import createCache from "@emotion/cache";
import { EmotionCache } from "@emotion/react";
import Clarity from "@microsoft/clarity";
import { AuthProvider } from "auth";
import { Amplify } from "aws-amplify";
import { NextPage } from "next";
import { AppProps } from "next/app";
import NProgress from "nprogress";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import Insight from "react-linkedin-insight-tag";
import { DefaultSeo, Toaster, UiProvider } from "ui-components";
import {
	AnalyticsProvider,
	I18nProvider,
	QueryProvider,
	ReactDevTools,
	TagManager,
} from "utils";

NProgress.configure({ showSpinner: false });

import "aos/dist/aos.css";
import "material-symbols/rounded.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "~ui-components/global.css";

Amplify.configure({
	API: {
		aws_appsync_graphqlEndpoint: process.env.API_URL,
		aws_appsync_authenticationType: "API_KEY",
		aws_appsync_apiKey: process.env.API_KEY,
	},
	ssr: true,
});

ReactDevTools.init();

const clientSideEmotionCache = createCache({ key: "css" });

export interface CustomAppProps extends AppProps {
	Component: NextPage;
	emotionCache?: EmotionCache;
}

// If any route has been moved to Devias theme, remove it from this list
const OLD_ROUTES = [
	"/confirm",
	"/franchise",
	"/pricing",
	"/privacy",
	"/security",
	"/terms",
	"/",
];

const App = ({
	Component,
	pageProps,
	emotionCache = clientSideEmotionCache,
	router,
}: CustomAppProps) => {
	const isOldRoute = OLD_ROUTES.includes(router.pathname);
	const isFranchise = router.pathname === "/franchise";

	useNprogress(router);

	useEffect(() => {
		async function initPixel() {
			if (!process.env.FACEBOOK_PIXEL_ID) return;

			const { default: Pixel } = await import("react-facebook-pixel");
			Pixel.init(process.env.FACEBOOK_PIXEL_ID);
		}

		void initPixel();
	}, []);

	useEffect(() => {
		if (!process.env.LINKEDIN_INSIGHT_TAG_ID) return;

		Insight.init(process.env.LINKEDIN_INSIGHT_TAG_ID);
	}, []);

	useEffect(() => {
		if (isFranchise) {
			if (process.env.FRANCHISE_GA_MEASUREMENT_ID)
				ReactGA.initialize(process.env.FRANCHISE_GA_MEASUREMENT_ID);
			if (process.env.FRANCHISE_GOOGLE_TAG_MANAGER_ID)
				TagManager.init(process.env.FRANCHISE_GOOGLE_TAG_MANAGER_ID);

			return;
		}

		if (process.env.GIS_GA_MEASUREMENT_ID)
			ReactGA.initialize(process.env.GIS_GA_MEASUREMENT_ID);
		if (process.env.GIS_GOOGLE_TAG_MANAGER_ID)
			TagManager.init(process.env.GIS_GOOGLE_TAG_MANAGER_ID);
	}, [isFranchise]);

	useEffect(() => {
		if (!process.env.MS_CLARITY_ID) return;

		Clarity.init(process.env.MS_CLARITY_ID);
	}, []);

	return (
		<>
			<DefaultSeo />
			<AnalyticsProvider
				id={
					isFranchise
						? process.env.FRANCHISE_GOOGLE_TAG_MANAGER_ID
						: process.env.GIS_GOOGLE_TAG_MANAGER_ID
				}>
				<I18nProvider language="en">
					<QueryProvider>
						<AuthProvider>
							{isOldRoute ? (
								<Component {...pageProps} />
							) : (
								<UiProvider emotionCache={emotionCache}>
									<Component {...pageProps} />
									<Toaster position="bottom-right" />
								</UiProvider>
							)}
						</AuthProvider>
					</QueryProvider>
				</I18nProvider>
			</AnalyticsProvider>
		</>
	);
};

export default App;

const useNprogress = (router) => {
	useEffect(() => {
		const handleRouteStart = () => NProgress.start();
		const handleRouteDone = () => NProgress.done();

		router.events.on("routeChangeStart", handleRouteStart);
		router.events.on("routeChangeComplete", handleRouteDone);
		router.events.on("routeChangeError", handleRouteDone);

		return () => {
			// Make sure to remove the event handler on unmount!
			router.events.off("routeChangeStart", handleRouteStart);
			router.events.off("routeChangeComplete", handleRouteDone);
			router.events.off("routeChangeError", handleRouteDone);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
