import { Box, Stack, Typography } from "@mui/material";

import { SvgLineComponent } from "~ui-components/components/molecules/legend/shapes/canvas/line";
import { LineCategoryConfigs } from "~ui-components/types/__generated/gql/graphql";

interface Props {
	style: LineCategoryConfigs;
}

export const LineCategory = ({ style }: Props) => {
	const { symbolValues } = style;
	return (
		<Box>
			{symbolValues?.map((symbol, index) => {
				return (
					symbol.symbol &&
					symbol.label && (
						<Stack
							key={index}
							direction="row"
							display="flex"
							alignItems="center">
							<SvgLineComponent symbol={symbol.symbol} />
							<Box sx={{ pl: 1 }}>
								<Typography
									color="text.secondary"
									variant="caption">
									{symbol.label}
								</Typography>
							</Box>
						</Stack>
					)
				);
			})}
		</Box>
	);
};
