import { Box, Stack, Typography } from "@mui/material";
import { PREDEFINED_MARKERS } from "ui-components";

import { PointCategoryConfigs } from "~ui-components/types/__generated/gql/graphql";

interface Props {
	style: PointCategoryConfigs;
}
export const PointCategory = ({ style }: Props) => {
	const { symbolValues } = style;
	const Icon =
		PREDEFINED_MARKERS[style.symbolStyle?.basic?.symbol ?? "defaultSymbol"]
			.Icon;
	return (
		<Box>
			{symbolValues?.map((symbol, index) => {
				return (
					<Stack
						key={index}
						direction="row"
						display="flex"
						alignItems="center">
						<Icon
							fontSize={style.symbolStyle?.basic?.size}
							weight="fill"
							color={symbol.color}
						/>
						<Box sx={{ pl: 1 }}>
							<Typography
								color="text.secondary"
								variant="caption">
								{symbol.label}
							</Typography>
						</Box>
					</Stack>
				);
			})}
		</Box>
	);
};
