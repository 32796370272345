import axios, { CancelTokenSource } from "axios";
import { paths } from "config/paths";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import Insight from "react-linkedin-insight-tag";
import {
	SignUpFormValues,
	SignUpRemoteFormValues,
	SignUpRemoteSchema,
	SignUpSchema,
	toast,
	SignUp as View,
} from "ui-components";
import { apiInstance, tryCatch } from "utils";
import { useBoolean } from "usehooks-ts";

import { useSignUp } from "~auth/queries/useSignUp";

let emailValidationSource: CancelTokenSource | null = null;
let companyNameValidationSource: CancelTokenSource | null = null;

export const SignUp = () => {
	const router = useRouter();

	const signUp = useSignUp();
	const form = useFormik<SignUpFormValues>({
		validateOnMount: true,
		isInitialValid: false,
		initialValues: {
			name: "",
			password: "",
			acceptedToTermsAndPolicy: false,
		},
		validationSchema: SignUpSchema,
		validate: (values) => {
			const [firstName, lastName] = values.name.split(" ");
			if (!firstName || !lastName)
				return {
					name: "Please enter your first and last name",
				};

			return {};
		},
		onSubmit: (values, helpers) => {
			const payload = { ...values, ...remoteForm.values };

			toast.promise(
				async () => {
					const [error] = await tryCatch(signUp.mutateAsync(payload));
					if (error) {
						helpers.setSubmitting(false);
						throw error;
					}

					if (process.env.NODE_ENV === "production") {
						const { default: Pixel } = await import("react-facebook-pixel");
						Pixel.track("StartTrial");
						Insight.track(18368692);
					}

					void router.replace(
						paths.signUpVerification + "?email=" + payload.email,
					);
				},
				{
					loading: "Signing you up...",
					success: "Success!",
					error: (e) =>
						axios.isAxiosError(e) ? "Something went wrong!" : e.message,
				},
			);
		},
	});
	const remoteForm = useFormik<SignUpRemoteFormValues>({
		validateOnMount: true,
		isInitialValid: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			email: "",
			companyName: "",
			portalUrl: "",
		},
		validationSchema: SignUpRemoteSchema,
		// eslint-disable-next-line @typescript-eslint/no-empty-function -- only use this form for validation
		onSubmit: () => {},
	});
	const { value: isRemotelyValidatingEmail, setValue: toggleValidatingEmail } =
		useBoolean(false);
	const {
		value: isRemotelyValidatingCompanyName,
		setValue: toggleValidatingCompanyName,
	} = useBoolean(false);

	const handleEmailValueChange = async (newValue: string) => {
		const [schemaErrorMessage] = await Promise.all([
			SignUpRemoteSchema.fields.email
				.validate(newValue)
				.then(() => null)
				.catch((e) => e.message as string),
			remoteForm.setFieldValue("email", newValue),
			remoteForm.setFieldTouched("email", true),
		]);
		if (schemaErrorMessage) {
			remoteForm.setFieldError("email", schemaErrorMessage);
			return;
		}

		toggleValidatingEmail(true);
		const [remoteError] = await tryCatch(remotelyValidateEmail(newValue));
		if (remoteError) {
			if (axios.isCancel(remoteError)) return;

			toggleValidatingEmail(false);
			remoteForm.setFieldError("email", remoteError.message);
			return;
		}
		toggleValidatingEmail(false);
		remoteForm.setFieldError("email", undefined);
	};

	const handleCompanyNameValueChange = async (newValue: string) => {
		const [schemaErrorMessage] = await Promise.all([
			SignUpRemoteSchema.fields.companyName
				.validate(newValue)
				.then(() => null)
				.catch((e) => e.message as string),
			remoteForm.setFieldValue("companyName", newValue),
			remoteForm.setFieldTouched("companyName", true),
			// manually reset portalUrl states to not trigger validation
			remoteForm.setFieldValue("portalUrl", ""),
			remoteForm.setFieldError("portalUrl", "Required"),
		]);
		if (schemaErrorMessage) {
			remoteForm.setFieldError("companyName", schemaErrorMessage);
			return;
		}

		toggleValidatingCompanyName(true);
		const [remoteError, portalUrl] = await tryCatch(
			remotelyValidateCompanyName(newValue),
		);
		if (remoteError) {
			if (axios.isCancel(remoteError)) return;

			toggleValidatingCompanyName(false);
			remoteForm.setFieldError("companyName", remoteError.message);
			return;
		}
		toggleValidatingCompanyName(false);
		remoteForm.setFieldError("companyName", undefined);

		// manually update field states to not trigger validation
		void remoteForm.setFieldValue("portalUrl", portalUrl);
		void remoteForm.setFieldTouched("portalUrl", true);
		remoteForm.setFieldError("portalUrl", undefined);
	};

	return (
		<View
			form={form}
			remoteForm={remoteForm}
			onEmailChange={(e) => handleEmailValueChange(e.target.value)}
			onEmailBlur={(e) => handleEmailValueChange(e.target.value)}
			onCompanyNameChange={(e) => handleCompanyNameValueChange(e.target.value)}
			onCompanyNameBlur={(e) => handleCompanyNameValueChange(e.target.value)}
			isRemotelyValidatingEmail={isRemotelyValidatingEmail}
			isRemotelyValidatingCompanyName={isRemotelyValidatingCompanyName}
		/>
	);
};

const remotelyValidateEmail = async (value: string) => {
	if (emailValidationSource) emailValidationSource.cancel();
	emailValidationSource = axios.CancelToken.source();

	const [error, data] = await tryCatch(
		apiInstance.get<{
			portal_url: string | null;
			status: string;
			users: Record<string, any>[];
		}>(`/account/search?term=${encodeURIComponent(value)}&qc=email`, {
			cancelToken: emailValidationSource.token,
		}),
	);

	if (error) {
		if (axios.isCancel(error)) throw error;
		if (axios.isAxiosError(error)) throw new Error("Something went wrong!");
		throw new Error(error.message);
	}
	if (data.users.length > 0)
		throw new Error(
			"This email is already in use. Please use another email address.",
		);
};

const remotelyValidateCompanyName = async (value: string) => {
	if (companyNameValidationSource) companyNameValidationSource.cancel();
	companyNameValidationSource = axios.CancelToken.source();

	const [error, data] = await tryCatch(
		apiInstance.get<{
			portal_url: string | null;
			status: string;
			users: Record<string, any>[];
		}>(`/account/search?term=${encodeURIComponent(value)}&qc=name`, {
			cancelToken: companyNameValidationSource.token,
		}),
	);

	if (error) {
		if (axios.isCancel(error)) throw error;
		if (axios.isAxiosError(error)) throw new Error("Something went wrong!");
		throw new Error(error.message);
	}
	if (!data.portal_url) throw new Error("Invalid company name");

	return data.portal_url;
};
