import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import { paths } from "config/paths";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import {
	PaidTrialUserAccount,
	PaidUserAccount,
	TrialUserAccount,
} from "~ui-components/components/organisms/user-accounts";
import { CancelSubscription } from "~ui-components/components/organisms/user-accounts/cancel-subscription";
import {
	InvoiceHistory,
	INVOICES,
} from "~ui-components/components/organisms/user-accounts/invoice-history";
import { UserProfile } from "~ui-components/components/organisms/user-accounts/user-profile";
import {
	SUBSCRIPTION_TYPE,
	User,
} from "~ui-components/types/__generated/gql/graphql";

export interface AccountProps {
	user: User;
}

export const Account: React.FC<AccountProps> = (props) => {
	const router = useRouter();

	const { user } = props;

	return (
		<DashboardContentLayout
			pageTitle="Account"
			action={
				<Stack
					direction="row"
					spacing={2}>
					<Button
						variant="contained"
						component={Link}
						href={paths.accountName.settings.profile(
							router.query["account-name"],
						)}>
						Manage profile
					</Button>
					<Button
						variant="contained"
						component={Link}
						href={paths.accountName.settings.editSubscription(
							router.query["account-name"],
						)}>
						Manage subscription
					</Button>
				</Stack>
			}>
			{user.subscription.type === SUBSCRIPTION_TYPE.TRIAL && (
				<TrialUserAccount user={user} />
			)}

			{user.subscription.type === SUBSCRIPTION_TYPE.PAID_TRIAL && (
				<PaidTrialUserAccount user={user} />
			)}

			{user.subscription.type === SUBSCRIPTION_TYPE.PAID && (
				<PaidUserAccount user={user} />
			)}

			<UserProfile
				items={[
					{
						name: "Name",
						value: user.name,
					},
					{ name: "Email", value: user.email },
					{ name: "Password", value: "********" },
					{ name: "Role", value: "Account Owner" },
					{ name: "Phone number", value: user.phone },
					{ name: "Company", value: user.companyName },
					{ name: "URL Slug", value: `https://mangomap.com/${user.slug}` },
				]}
			/>

			{user.subscription.type === SUBSCRIPTION_TYPE.PAID && (
				<InvoiceHistory
					tableProps={{ rows: INVOICES }}
					paginationProps={{
						page: 0,
						count: INVOICES.length + 100,
						onPageChange: console.log,
						onRowsPerPageChange: console.log,
					}}
				/>
			)}

			{user.subscription.type !== SUBSCRIPTION_TYPE.TRIAL && (
				<CancelSubscription />
			)}
		</DashboardContentLayout>
	);
};
