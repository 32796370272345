import { useQuery } from "@tanstack/react-query";
import { DEFAULT_USER_ID } from "config/constants";
import { graphierQL } from "utils";

import {
	GetUserQuery,
	GetUserQueryVariables,
} from "~auth/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			createdAt
			updatedAt
			name
			email
			phone
			avatar
			companyName
			slug
			billingAddress {
				companyName
				vatNumber
				firstName
				lastName
				streetLine1
				streetLine2
				city
				province
				postalCode
				country
			}
			subscription {
				type
				trialExpiresAt
				paidConfig {
					cycle
					plan
					addons {
						admins
						editors
						maps
						storage
					}
				}
			}
			paymentMethod {
				cardName
				cardNumber
				cardExpiresAt
				cardCvv
			}
		}
	}
`;

export type GetUserVariables = GetUserQueryVariables;
export type GetUserResults = GetUserQuery["getUser"];

export const getUserQueryKey = () => ["user"];

export const getUserQueryFn = async () => {
	return graphierQL<GetUserQuery, GetUserVariables>({
		query,
		variables: {
			id: DEFAULT_USER_ID,
		},
	}).then((res) => res.getUser);
};

export const useGetUser = () => {
	return useQuery({
		enabled: false, // Disable the query by default, we want to call this manually
		queryKey: getUserQueryKey(),
		queryFn: getUserQueryFn,
	});
};
