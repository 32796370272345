import * as React from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { MembersSelectionProvider } from "~ui-components/contexts/member-selection";

import { GroupEditForm } from "./group-edit-form";

export function GroupEdit(props): React.JSX.Element {
	const { form, members } = props;

	return (
		<DashboardContentLayout pageTitle="Edit group">
			<MembersSelectionProvider members={members}>
				<GroupEditForm
					form={form}
					rows={members}
				/>
			</MembersSelectionProvider>
		</DashboardContentLayout>
	);
}

export * from "./group-edit-form";
