import * as React from "react";

import type { Selection } from "~ui-components/hooks/use-selection";
import { useSelection } from "~ui-components/hooks/use-selection";
import { Group } from "~ui-components/types/group";

function noop(): void {
	return undefined;
}

export const GroupsSelectionContext = React.createContext<Selection>({
	deselectAll: noop,
	deselectOne: noop,
	selectAll: noop,
	selectOne: noop,
	selected: new Set(),
	selectedAny: false,
	selectedAll: false,
});

interface GroupsSelectionProviderProps {
	children: React.ReactNode;
	groups: Group[];
}

export function GroupsSelectionProvider({
	children,
	groups = [],
}: GroupsSelectionProviderProps): React.JSX.Element {
	const groupIds = React.useMemo(
		() => groups.map((group) => group.id),

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[groups.length],
	);

	const selection = useSelection(groupIds);

	return (
		<GroupsSelectionContext.Provider value={selection}>
			{children}
		</GroupsSelectionContext.Provider>
	);
}

export function useGroupsSelection(): Selection {
	return React.useContext(GroupsSelectionContext);
}
