import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import * as yup from "yup";
import { useFormik } from "formik";

import {
	ColumnDef,
	DataTable,
} from "~ui-components/components/atoms/data-table";
import { Member } from "~ui-components/types/member";
import { useMembersSelection } from "~ui-components/contexts/member-selection";
import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";

export const GroupEditSchema = yup.object({
	name: yup.string().required("Required"),
	members: yup.array(),
});

export type GroupEditFormValues = yup.InferType<typeof GroupEditSchema>;

export interface GroupEditFormProps {
	form: ReturnType<typeof useFormik<GroupEditFormValues>>;
	rows: Member[];
}

const columns = [
	{
		formatter: (row): React.JSX.Element => (
			<Stack
				direction="row"
				spacing={1}
				sx={{ alignItems: "center" }}>
				<div>
					<Typography
						color="text.primary"
						sx={{ whiteSpace: "nowrap" }}
						variant="subtitle2">
						{row.name}
					</Typography>
					<Typography
						color="text.secondary"
						variant="body2">
						{row.email}
					</Typography>
				</div>
			</Stack>
		),
		name: "Select all",
	},
	{
		formatter: (row): React.JSX.Element => (
			<Chip
				label={row.role}
				color="primary"></Chip>
		),
		name: "Role",
	},
] as ColumnDef<Member>[];

export function GroupEditForm(props: GroupEditFormProps): React.JSX.Element {
	const { form, rows } = props;
	const { deselectAll, deselectOne, selectAll, selectOne, selected } =
		useMembersSelection();

	React.useEffect(() => {
		void form.setFieldValue("members", Array.from(selected));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	return (
		<form onSubmit={form.handleSubmit}>
			<Card>
				<CardContent>
					<Stack
						divider={<Divider />}
						spacing={4}>
						<Stack spacing={3}>
							<Typography variant="h6">Group information</Typography>
							<Grid
								container
								spacing={3}>
								<Grid xs={12}>
									<FormControl
										fullWidth
										error={!!form.errors.name}>
										<InputLabel>Name</InputLabel>
										<OutlinedInput {...form.getFieldProps("name")} />
										{form.errors?.name && (
											<FormHelperText>{form.errors.name}</FormHelperText>
										)}
									</FormControl>
								</Grid>
							</Grid>
						</Stack>
						<Stack spacing={3}>
							<Typography variant="h6">Members information</Typography>
							<Grid
								container
								spacing={3}>
								<Grid xs={12}>
									<Card>
										<DataTable<Member>
											selectable
											uniqueRowId={(row) => row.id}
											selected={new Set(Array.from(form.values.members ?? []))}
											columns={columns}
											rows={rows}
											onDeselectAll={deselectAll}
											onDeselectOne={(_, row) => {
												deselectOne(row.id);
											}}
											onSelectAll={selectAll}
											onSelectOne={(_, row) => {
												selectOne(row.id);
											}}></DataTable>
										{!rows.length ? (
											<Box sx={{ p: 3 }}>
												<Typography
													color="text.secondary"
													sx={{ textAlign: "center" }}
													variant="body2">
													No members found
												</Typography>
											</Box>
										) : null}
									</Card>
								</Grid>
							</Grid>
						</Stack>
					</Stack>
				</CardContent>
				<CardActions sx={{ justifyContent: "flex-end" }}>
					<ButtonCancel />
					<Button
						type="submit"
						variant="contained"
						disabled={!form.isValid}>
						Save changes
					</Button>
				</CardActions>
			</Card>
		</form>
	);
}
