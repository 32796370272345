import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import { CreditCard as CreditCardIcon } from "@phosphor-icons/react/dist/ssr/CreditCard";
import { FC } from "react";

import { TrialRemainingCard } from "~ui-components/index";
import { dayjs } from "~ui-components/libs/dayjs";
import { User } from "~ui-components/types/__generated/gql/graphql";
import {
	Summary,
	USERS_SUMMARY,
	useTrialPlanSummary,
} from "../subscription-summary";

export const TrialUserAccount: FC<{ user: User }> = ({ user }) => {
	const planSummary = useTrialPlanSummary();
	const total = 30;
	const remainingDays = dayjs(user.subscription.trialExpiresAt).diff(
		dayjs(),
		"day",
	);

	const cardContent = {
		title: "Welcome to your trial",
		content: `Try any feature and any plan free for ${total} days, no obligation. \nIf you have any questions or specific procurement need, please don&apos;t hesitate to contact our responsive support team.`,
	};

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<CreditCardIcon fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="Subscription"
			/>
			<CardContent>
				<Stack
					direction={{ xs: "column", sm: "row" }}
					spacing={3}>
					<Stack
						spacing={3}
						sx={{
							flex: "0 0 auto",
							justifyContent: "space-between",
							width: "100%",
						}}>
						<TrialRemainingCard
							remainingDays={remainingDays}
							total={total}
							title={cardContent.title}
							content={cardContent.content}
						/>
						<Summary items={planSummary} />
						<Summary items={USERS_SUMMARY} />
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};
