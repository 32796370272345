import axios from "axios";

export interface UploadFileArgs {
	url: string;
	formData: Record<string, string>;
	file: File;
}

const uploadFile = async ({ url, formData, file }: UploadFileArgs) => {
	const form = new FormData();
	Object.entries(formData).forEach(([k, v]) => form.append(k, v));
	form.append("file", file); // must be the last one
	return await axios.post(url, form);
};

export { uploadFile };
