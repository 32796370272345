import * as React from "react";

import type { Selection } from "~ui-components/hooks/use-selection";
import { useSelection } from "~ui-components/hooks/use-selection";
import { Member } from "~ui-components/types/member";

function noop(): void {
	return undefined;
}

export const MembersSelectionContext = React.createContext<Selection>({
	deselectAll: noop,
	deselectOne: noop,
	selectAll: noop,
	selectOne: noop,
	selected: new Set(),
	selectedAny: false,
	selectedAll: false,
});

interface MembersSelectionProviderProps {
	children: React.ReactNode;
	members: Member[];
}

export function MembersSelectionProvider({
	children,
	members = [],
}: MembersSelectionProviderProps): React.JSX.Element {
	const memberIds = React.useMemo(
		() => members.map((Member) => Member.id),
		[members],
	);
	const selection = useSelection(memberIds);

	return (
		<MembersSelectionContext.Provider value={selection}>
			{children}
		</MembersSelectionContext.Provider>
	);
}

export function useMembersSelection(): Selection {
	return React.useContext(MembersSelectionContext);
}
