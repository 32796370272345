import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { GridFour as GridFourIcon } from "@phosphor-icons/react/dist/ssr/GridFour";
import { GridNine as GridNineIcon } from "@phosphor-icons/react/dist/ssr/GridNine";
import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react/dist/ssr/MagnifyingGlass";
import { Rows as RowsIcon } from "@phosphor-icons/react/dist/ssr/Rows";
import { paths } from "config/paths";
import { debounce, isEmpty } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { Option } from "~ui-components/components/atoms/option";

export interface Filters {
	query?: string;
}

export type Tab = "maps" | "data";

export type ViewMode = "row" | "medium" | "large";

export type SortDir = "asc" | "desc";

export interface PortalFiltersProps {
	tab: Tab;
	filters: Filters;
	sortDir: SortDir;
	view: ViewMode;
	setView: (view: ViewMode) => void;
	setSort: (sort: SortDir) => void;
	setFilters: (query: string) => void;
}

export const PortalFilters: React.FC<PortalFiltersProps> = ({
	tab = "maps",
	sortDir = "desc",
	view = "row",
	setView,
	setSort,
	filters,
	setFilters,
}) => {
	const router = useRouter();

	const inputRef = React.useRef<HTMLInputElement>(null);

	const handleViewChange = (value: ViewMode) => {
		if (isEmpty(value)) return;
		setView(value);
	};

	useHotkeys(
		["meta+f", "ctrl+f"],
		() => {
			inputRef.current?.focus();
		},
		{ preventDefault: true },
	);

	return (
		<Card>
			<Stack
				direction="row"
				spacing={4}
				sx={{ alignItems: "center", flexWrap: "wrap", p: 2 }}>
				<Stack
					spacing={2}
					sx={{ pl: 2 }}>
					<Tabs value={tab}>
						<Tab
							component={Link}
							label="Maps"
							value="maps"
							href={paths.accountName.maps.root(router.query["account-name"])}
						/>
						<Tab
							component={Link}
							label="Data"
							value="data"
							href={paths.accountName.data.root(router.query["account-name"])}
						/>
					</Tabs>
				</Stack>
				<OutlinedInput
					inputRef={inputRef}
					name="name"
					placeholder="Search"
					startAdornment={
						<InputAdornment position="start">
							<MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
						</InputAdornment>
					}
					sx={{ flex: "1 1 auto" }}
					autoFocus={!!filters.query}
					defaultValue={filters.query}
					onChange={debounce((e) => setFilters(e.target.value), 250)}
				/>
				<Select
					name="sort"
					sx={{ maxWidth: "100%", width: "120px" }}
					value={sortDir}
					onChange={(e) => setSort(e.target.value as SortDir)}>
					<Option value="desc">Newest</Option>
					<Option value="asc">Oldest</Option>
				</Select>
				<ToggleButtonGroup
					color="primary"
					exclusive
					onChange={(_, value: ViewMode) => {
						handleViewChange(value);
					}}
					onKeyUp={(event) => {
						if (event.key === "Enter" || event.key === " ") {
							handleViewChange(view === "row" ? "row" : "medium");
						}
					}}
					tabIndex={0}
					value={view}>
					<ToggleButton value="row">
						<RowsIcon />
					</ToggleButton>
					<ToggleButton value="medium">
						<GridNineIcon />
					</ToggleButton>
					<ToggleButton value="large">
						<GridFourIcon />
					</ToggleButton>
				</ToggleButtonGroup>
			</Stack>
		</Card>
	);
};
