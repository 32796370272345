import {
	Alert,
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	FormControl,
	FormHelperText,
	InputLabel,
	LinearProgress,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { CloudArrowUp as CloudArrowUpIcon } from "@phosphor-icons/react/dist/ssr/CloudArrowUp";
import { AxiosRequestConfig } from "axios";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { FC, Fragment, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import {
	Dataset,
	DATASOURCE_TYPE,
	GEOMETRY_TYPE,
} from "~ui-components/types/__generated/gql/graphql";
import { getRequiredFileExtensionsFromDatasourceType } from "../data-import";

export const DatasetUpdateSchema = Yup.object({
	name: Yup.string().required("Required"),
	description: Yup.string().notRequired(),
	dataFormat: Yup.mixed<DATASOURCE_TYPE>().required("Required"),
	files: Yup.array<File>().required("Required"),
});

export type DatasetUpdateFormValues = Yup.InferType<typeof DatasetUpdateSchema>;

export interface DatasetUpdateProps {
	dataset: Dataset;
	form: ReturnType<typeof useFormik<DatasetUpdateFormValues>>;
	onUploadFile: (
		file: File,
		onUploadProgressCallback: AxiosRequestConfig["onUploadProgress"],
	) => Promise<void>;
}

const DATASOURCE_TYPE_OPTIONS: Record<
	DATASOURCE_TYPE.CSV | DATASOURCE_TYPE.SHAPEFILE | DATASOURCE_TYPE.TAB,
	string
> = {
	[DATASOURCE_TYPE.CSV]: "CSV",
	[DATASOURCE_TYPE.SHAPEFILE]: "Shapefile",
	[DATASOURCE_TYPE.TAB]: "Tab",
};

export const DatasetUpdate: FC<DatasetUpdateProps> = ({
	dataset,
	form,
	onUploadFile,
}) => {
	const router = useRouter();

	const requiredFileExtensions = getRequiredFileExtensionsFromDatasourceType(
		form.values.dataFormat,
	);
	const areFilesSelected = form.values.files.length > 0;
	const [uploadProgresses, setUploadProgresses] = useState<
		Record<string, number>
	>({});

	const {
		getRootProps,
		getInputProps,
		open: openFileDialog,
	} = useDropzone({
		accept: {
			"*/*": requiredFileExtensions.map((ext) => `.${ext}`),
		},
		multiple: requiredFileExtensions.length > 1,
		maxFiles: requiredFileExtensions.length,
		onDrop: async (acceptedFiles) => {
			const missingExts = requiredFileExtensions.filter((ext) =>
				form.values.files.every((file) => !file.name.endsWith(`.${ext}`)),
			);
			const newFiles = acceptedFiles.filter((file) =>
				missingExts.some((ext) => file.name.endsWith(ext)),
			);
			await form.setFieldValue("files", [...form.values.files, ...newFiles]);

			// no need to await here
			newFiles.forEach((file) =>
				onUploadFile(file, (event) => {
					setUploadProgresses((prev) => ({
						...prev,
						[file.name.split(".").slice(1).join(".")]:
							(event.loaded / event.total) * 100,
					}));
				}),
			);
		},
	});

	useEffect(() => {
		setUploadProgresses({});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.values.dataFormat]);

	return (
		<DashboardContentLayout pageTitle="Data config">
			<Stack
				direction={{ xs: "column", md: "row" }}
				spacing={4}
				sx={{ position: "relative" }}>
				<Box sx={{ flex: "1 1 auto", minWidth: 0 }}>
					<form onSubmit={form.handleSubmit}>
						<Card>
							<CardHeader
								avatar={
									<Avatar>
										<CloudArrowUpIcon fontSize="var(--Icon-fontSize)" />
									</Avatar>
								}
								title="Update details or upload a new version of this data"
							/>

							<CardContent>
								<Stack spacing={3}>
									<FormControl>
										<InputLabel>Name</InputLabel>
										<TextField
											autoFocus
											{...form.getFieldProps("name")}
											error={!!form.errors.name}
											helperText={form.errors.name}
										/>
									</FormControl>

									<FormControl fullWidth>
										<InputLabel>Description</InputLabel>
										<TextField
											minRows={3}
											multiline
											{...form.getFieldProps("description")}
											error={!!form.errors.description}
											helperText={form.errors.description}
										/>
									</FormControl>

									{dataset?.type === GEOMETRY_TYPE.WMS && (
										<FormControl>
											<InputLabel>Source</InputLabel>
											<TextField
												disabled
												value={dataset.getCapabilitiesUrl}
											/>
										</FormControl>
									)}

									{![GEOMETRY_TYPE.WMS, GEOMETRY_TYPE.RASTER].includes(
										dataset?.type,
									) && (
										<>
											<Stack>
												<Typography variant="h6">Reupload data</Typography>
												<Typography variant="body2">
													All maps using this data will be updated to reflect
													the changes.
												</Typography>
											</Stack>

											<FormControl fullWidth>
												<InputLabel>Data format</InputLabel>
												<Stack
													direction="row"
													gap={3}>
													<TextField
														select
														{...form.getFieldProps("dataFormat")}
														error={!!form.errors.dataFormat}
														helperText={form.errors.dataFormat}
														sx={{ width: "150px" }}>
														{Object.keys(DATASOURCE_TYPE_OPTIONS).map((key) => (
															<MenuItem
																key={key}
																value={key}>
																{DATASOURCE_TYPE_OPTIONS[key]}
															</MenuItem>
														))}
													</TextField>
													<Button
														variant="contained"
														sx={{ whiteSpace: "nowrap" }}
														onClick={openFileDialog}>
														Upload files
													</Button>
												</Stack>
											</FormControl>

											<Stack
												{...getRootProps()}
												spacing={3}>
												<input {...getInputProps()} />

												<Stack>
													<InputLabel>Required files</InputLabel>
													<Divider />
													{!!form.errors.files && (
														<FormHelperText error>
															{form.errors.files.toString()}
														</FormHelperText>
													)}
												</Stack>

												{requiredFileExtensions.map((ext, index) => {
													const matchingFile = form.values.files.find((file) =>
														file.name.endsWith(`.${ext}`),
													);

													return (
														<Fragment key={ext}>
															<Stack
																direction="row"
																spacing={3}>
																<Typography
																	flex={1}
																	color="text.secondary"
																	variant="body2">
																	{`.${ext}`}
																</Typography>
																{!!areFilesSelected && (
																	<Typography
																		flex={2}
																		fontWeight={600}
																		variant="body2"
																		sx={{
																			maxWidth: "100%",
																			whiteSpace: "nowrap",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																		}}>
																		{matchingFile ? matchingFile.name : ""}
																	</Typography>
																)}
																<Box flex={3}>
																	{!!areFilesSelected &&
																		(matchingFile ? (
																			<LinearProgress
																				variant={
																					uploadProgresses[ext]
																						? "determinate"
																						: "indeterminate"
																				}
																				color={
																					(uploadProgresses[ext] ?? 0) < 100
																						? "primary"
																						: "success"
																				}
																				value={uploadProgresses[ext] ?? 0}
																			/>
																		) : (
																			<Alert
																				severity="error"
																				action={
																					<Button
																						color="error"
																						size="small"
																						onClick={() => {
																							openFileDialog();
																						}}>
																						Upload
																					</Button>
																				}>
																				File missing
																			</Alert>
																		))}
																</Box>
															</Stack>
															{index !== requiredFileExtensions.length - 1 && (
																<Divider />
															)}
														</Fragment>
													);
												})}
											</Stack>
										</>
									)}
								</Stack>
							</CardContent>

							<CardActions sx={{ justifyContent: "flex-end" }}>
								<Button
									color="secondary"
									onClick={router.back}>
									Cancel
								</Button>
								<Button
									variant="contained"
									type="submit"
									disabled={
										form.isSubmitting ||
										!form.isValid ||
										Object.keys(uploadProgresses).some(
											(key) => (uploadProgresses[key] ?? 0) < 100,
										)
									}>
									Save
								</Button>
							</CardActions>
						</Card>
					</form>
				</Box>
			</Stack>
		</DashboardContentLayout>
	);
};
