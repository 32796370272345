import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { DownloadSimple as DownloadSimpleIcon } from "@phosphor-icons/react/dist/ssr/DownloadSimple";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { paths } from "config/paths";
import Link from "next/link";
import { useRouter } from "next/router";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";

import { AccessGroupsPagination } from "./access-groups-pagination";
import { AccessGroupsTable } from "./access-groups-table";
import { UsersPagination } from "./users-pagination";
import { UsersTable } from "./users-table";
import { UsersTabs } from "./users-tabs";

const users = [
	{
		id: "1",
		name: "Terry Towelling",
		email: "terry@consultant.com",
		role: "Admin",
		groups: ["Managers"],
	},
	{
		id: "2",
		name: "Francis Bacon",
		email: "fran@consultant.com",
		role: "Editor",
		groups: ["Managers", "Accounting"],
	},
	{
		id: "3",
		name: "Tony Bennet",
		email: "tony@consultant.com",
		role: "Editor",
		groups: ["New Zealand team"],
	},
	{
		id: "4",
		name: "Hope Smith",
		email: "hope@consultant.com",
		role: "Editor",
		groups: ["External consultants"],
	},
	{
		id: "5",
		name: "Maggie Smith",
		email: "mags@consultant.com",
		role: "Viewer",
		groups: ["External consultants"],
	},
	{
		id: "6",
		name: "Rupert Cumberland",
		email: "rup1988@consultant.com",
		role: "Viewer",
		groups: ["Interns"],
	},
];

const accessGroups = [
	{
		id: "1",
		name: "New Zealand team",
		members: 3,
	},
	{
		id: "2",
		name: "Intern",
		members: 1,
	},
	{
		id: "3",
		name: "External consultants",
		members: 4,
	},
	{
		id: "4",
		name: "Managers",
		members: 2,
	},
	{
		id: "5",
		name: "Accounting",
		members: 4,
	},
];

interface UsersProps {
	tab: "users" | "access-groups";
	onUserDelete: () => void;
	onAccessGroupDelete: () => void;
}

export function Users(props: UsersProps) {
	const router = useRouter();

	const { tab = "users", onUserDelete, onAccessGroupDelete } = props;

	return (
		<DashboardContentLayout
			pageTitle="Users"
			action={
				<Stack
					direction="row"
					justifyContent="flex-end"
					spacing={2}>
					<Button
						href={paths.accountName.users.invite(router.query["account-name"])}
						component={Link}
						startIcon={<PlusIcon />}
						variant="contained">
						Invite user
					</Button>
					<Button
						href={paths.accountName.groups.create(router.query["account-name"])}
						component={Link}
						startIcon={<PlusIcon />}
						variant="contained">
						Add group
					</Button>
					<Button
						startIcon={<DownloadSimpleIcon />}
						variant="outlined">
						Export user log
					</Button>
				</Stack>
			}>
			<Card>
				<UsersTabs
					value={tab}
					usersCount={users.length}
					accessGroupsCount={accessGroups.length}
				/>
				<Divider />
				<Box sx={{ overflowX: "auto" }}>
					{tab === "users" && (
						<UsersTable
							rows={users}
							onItemDelete={onUserDelete}
						/>
					)}
					{tab === "access-groups" && (
						<AccessGroupsTable
							rows={accessGroups}
							onItemDelete={onAccessGroupDelete}
						/>
					)}
				</Box>
				<Divider />
				{tab === "users" && (
					<UsersPagination
						count={users.length}
						page={0}
					/>
				)}
				{tab === "access-groups" && (
					<AccessGroupsPagination
						count={accessGroups.length}
						page={0}
					/>
				)}
			</Card>
		</DashboardContentLayout>
	);
}
