import { Switch } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { ArrowRight as ArrowRightIcon } from "@phosphor-icons/react/dist/ssr/ArrowRight";
import { Question } from "@phosphor-icons/react/dist/ssr/Question";
import { Smiley } from "@phosphor-icons/react/dist/ssr/Smiley";
import { Video } from "@phosphor-icons/react/dist/ssr/Video";
import { railsServerUrls } from "config/rails-server-urls";
import { FC } from "react";

import { DashboardContentLayout } from "~ui-components/components/atoms/dashboard-content-layout";
import { HelperWidget } from "~ui-components/components/organisms/support-panels/helper-widget";

export const Support: FC = () => {
	return (
		<DashboardContentLayout pageTitle="Support">
			<Grid
				container
				spacing={4}>
				<Grid
					md={4}
					xs={12}>
					<HelperWidget
						icon={Video}
						label="Tutorials"
						title="Video tutorials"
						description="In-depth tutorials for common workflows."
						action={
							<Button
								color="secondary"
								endIcon={<ArrowRightIcon />}
								size="small"
								href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&t=43s"
								target="_blank">
								Watch now
							</Button>
						}
					/>
				</Grid>
				<Grid
					md={4}
					xs={12}>
					<HelperWidget
						icon={Question}
						label="Help Center"
						title="Knowledge base"
						description="Search our knowledge base for answers and tips."
						additionalBody={
							<Stack
								direction="row"
								gap={1}
								alignItems="center">
								<Switch />
								<Typography
									variant="body2"
									fontWeight={600}>
									Display support widget
								</Typography>
							</Stack>
						}
						action={
							<Button
								color="secondary"
								endIcon={<ArrowRightIcon />}
								size="small"
								href={railsServerUrls.helpCentre}
								target="_blank">
								Help Center
							</Button>
						}
					/>
				</Grid>
				<Grid
					md={4}
					xs={12}>
					<HelperWidget
						icon={Smiley}
						label="Get help from an expert"
						title="Submit a ticket"
						description="Stuck and need to ask a mapping pro? Found an issue or bug? Submit a support ticket for prompt resolution."
						action={
							<Button
								color="secondary"
								endIcon={<ArrowRightIcon />}
								size="small"
								href={railsServerUrls.submitHelpRequest}
								target="_blank">
								Submit a ticket
							</Button>
						}
					/>
				</Grid>
			</Grid>
		</DashboardContentLayout>
	);
};
