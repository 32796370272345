import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	FormControl,
	InputAdornment,
	InputLabel,
	Stack,
	TextField,
} from "@mui/material";
import { User } from "@phosphor-icons/react/dist/ssr/User";
import { useFormik } from "formik";
import { FC } from "react";
import * as Yup from "yup";

export const BasicDetailsSchema = Yup.object({
	name: Yup.string().required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
	phone: Yup.string().required("Required"),
	urlSlug: Yup.string().required("Required"),
	role: Yup.string().required("Required"),
	companyName: Yup.string().required("Required"),
});

export type BasicDetailsFormValues = Yup.InferType<typeof BasicDetailsSchema>;

export interface BasicDetailsProps {
	form: ReturnType<typeof useFormik<BasicDetailsFormValues>>;
}

export const BasicDetails: FC<BasicDetailsProps> = ({ form }) => {
	return (
		<Card
			component="form"
			onSubmit={form.handleSubmit}>
			<CardHeader
				avatar={
					<Avatar>
						<User fontSize="var(--Icon-fontSize)" />
					</Avatar>
				}
				title="User Profile"
			/>
			<CardContent>
				<Stack spacing={3}>
					<FormControl>
						<InputLabel>Name</InputLabel>
						<TextField
							{...form.getFieldProps("name")}
							error={!!form.errors.name}
							helperText={form.errors.name}
						/>
					</FormControl>
					<FormControl>
						<InputLabel>Role</InputLabel>
						<TextField
							{...form.getFieldProps("role")}
							error={!!form.errors.name}
							helperText={form.errors.name}
							inputProps={{ readOnly: true }}
							variant="standard"
						/>
					</FormControl>
					<FormControl>
						<InputLabel>Email</InputLabel>
						<TextField
							type="email"
							{...form.getFieldProps("email")}
							error={!!form.errors.email}
							helperText={form.errors.email}
						/>
					</FormControl>
					<FormControl>
						<InputLabel>CompanyName</InputLabel>
						<TextField
							{...form.getFieldProps("companyName")}
							error={!!form.errors.companyName}
							helperText={form.errors.companyName}
						/>
					</FormControl>
					<FormControl>
						<InputLabel>Phone number</InputLabel>
						<TextField
							{...form.getFieldProps("phone")}
							error={!!form.errors.phone}
							helperText={form.errors.phone}
						/>
					</FormControl>
					<FormControl>
						<InputLabel>URL Slug</InputLabel>
						<TextField
							{...form.getFieldProps("urlSlug")}
							error={!!form.errors.urlSlug}
							helperText={form.errors.urlSlug ?? "Proceed with caution."}
							InputProps={{
								startAdornment: (
									<InputAdornment
										position="start"
										sx={{ color: "text.secondary" }}>
										https://mangomap.com/
									</InputAdornment>
								),
							}}
						/>
					</FormControl>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end", alignItems: "center" }}>
				<Button
					color="inherit"
					onClick={console.log}>
					Cancel
				</Button>
				<Button
					variant="contained"
					type="submit"
					disabled={form.isSubmitting || !form.isValid}>
					Save changes
				</Button>
			</CardActions>
		</Card>
	);
};
