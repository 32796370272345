import { Box, Stack, Typography } from "@mui/material";

import { SvgLineComponent } from "~ui-components/components/molecules/legend/shapes/canvas/line";
import { PointQuantity } from "~ui-components/components/molecules/legend/shapes/quantity/point";
import {
	LineQuantityConfigs,
	PointQuantityConfigs,
	PolygonQuantityConfigs,
} from "~ui-components/types/__generated/gql/graphql";

interface QuantityStyle {
	style: PointQuantityConfigs | PolygonQuantityConfigs | LineQuantityConfigs;
}

export const Quantity = (props: QuantityStyle) => {
	const {
		style: { legendType, symbolValues, symbolStyle, type },
	} = props;
	if (legendType === "vertical")
		return (
			<VerticalQuantity
				type={type}
				symbolStyle={symbolStyle}
				symbolValues={symbolValues}
			/>
		);
	return (
		<HorizontalQuantity
			type={type}
			symbolStyle={symbolStyle}
			symbolValues={symbolValues}
		/>
	);
};

const HorizontalQuantity = ({ symbolValues, symbolStyle, type }) => {
	return (
		<Stack direction={"column"}>
			<Stack
				direction={"row"}
				spacing={1}>
				{symbolValues.map((symbol, index) => {
					return (
						<Stack
							key={index}
							direction="column"
							display="flex"
							alignItems="center"
							sx={{ flexGrow: 1 }}>
							{type.match("_POINT") && (
								<PointQuantity
									{...symbol}
									symbolStyle={symbolStyle}
								/>
							)}
							{type.match("LINE_") && (
								<SvgLineComponent
									symbol={{ ...symbol.symbol, stroke: symbolStyle.stroke }}
								/>
							)}
							{type.match("POLYGON_") && (
								<Box
									width={20}
									height={20}
									style={{
										border: `${symbolStyle.stroke.width}px ${symbolStyle.stroke.style} ${symbolStyle.stroke.color}`,
									}}
									bgcolor={symbol?.symbol?.fill?.color || "#fff"}
								/>
							)}
						</Stack>
					);
				})}
			</Stack>
			<Stack direction={"row"}>
				{symbolValues.map((symbol, index) => {
					if (index === 0 || index === symbolValues.length - 1) {
						return (
							<Box
								key={index}
								sx={{
									flexGrow: 1,
									pt: 1,
									textAlign: index === symbolValues.length - 1 ? "right " : "",
								}}>
								<Typography
									color="text.secondary"
									variant="caption">
									{symbol.label}
								</Typography>
							</Box>
						);
					} else {
						return (
							<Box
								key={index}
								sx={{ flexGrow: 1 }}
							/>
						);
					}
				})}
			</Stack>
		</Stack>
	);
};

const VerticalQuantity = ({ symbolValues, symbolStyle, type }) => {
	return (
		<Box>
			{symbolValues.map((symbol, index) => {
				return (
					<Stack
						key={index}
						direction="row"
						display="flex"
						alignItems="center">
						{type.match("POINT_") && (
							<PointQuantity
								{...symbol}
								symbolStyle={symbolStyle}
							/>
						)}
						{type.match("LINE_") && (
							<SvgLineComponent
								symbol={{ ...symbol.symbol, stroke: symbolStyle.stroke }}
							/>
						)}
						{type.match("POLYGON_") && (
							<Box
								width={20}
								height={20}
								style={{
									border: `${symbolStyle.stroke.width}px ${symbolStyle.stroke.style} ${symbolStyle.stroke.color}`,
								}}
								bgcolor={symbol?.symbol?.fill?.color || "#fff"}
							/>
						)}
						<Box sx={{ pl: 1 }}>
							<Typography
								color="text.secondary"
								variant="caption">
								{symbol.label}
							</Typography>
						</Box>
					</Stack>
				);
			})}
		</Box>
	);
};
