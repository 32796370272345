import { GraphQLQuery } from "@aws-amplify/api";
import { GraphQLOptions } from "@aws-amplify/api-graphql";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
import { Amplify, API } from "aws-amplify";

import { getCurrentSession } from "~utils/auth/getCurrentSession";

/** A simple typesafe helper to make your life easier when using Amplify `graphql` */
export const graphierQL = async <TResults = unknown, TArgs = object>(
	_opts: Pick<GraphQLOptions, "query"> & {
		variables?: TArgs;
		useToken?: boolean;
	},
	customContext?: typeof Amplify,
): Promise<TResults> => {
	const opts: GraphQLOptions = {
		query: _opts.query,
	};

	if (_opts.variables) opts.variables = _opts.variables;

	if (_opts.useToken) {
		const token = await getCurrentSession(customContext).then((session) =>
			session.getIdToken().getJwtToken(),
		);
		opts.authMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS;
		opts.authToken = token ?? undefined;
	}

	const api: typeof API = customContext ? customContext.API : API;

	return api
		.graphql<GraphQLQuery<TResults>>(opts)
		.then((res) => res.data as TResults)
		.catch((res) => {
			throw res.errors;
		});
};
