import { graphierQL } from "~utils/api/graphierQL";
import { Query, QuerygetLayerArgs } from "~utils/types/__generated/gql/graphql";

const symbolStyle = `
   {
    name
    isGraphic
    basic {
      size
      rotation
      symbol
      radius
      opacity
    }
    fill {
      width
      fillType
      style
      color
      opacity
      isDisabled
    }
    stroke {
      style
      color
      width
      opacity
      isDisabled
    }
  }
`;

const symbolValue = `
  {
    color
    value
    label
    count
    symbol ${symbolStyle}
    id
    range
    legendVisibility
    min
    max
  }
`;

const lineCategoryStyle = `
  lineCategoryStyle {
    colorRamp
    symbolStyle ${symbolStyle}
    valueField
    symbolValues ${symbolValue}
  }
`;

const lineSimpleStyle = `
	lineSimpleStyle {
    symbolStyle ${symbolStyle}
	}
`;

const lineQuantityStyle = `
  lineQuantityStyle {
    valueField
    legendType
    classNumber
    colorRamp
    classification
    symbolStyle ${symbolStyle} 
    symbolValues ${symbolValue}
    classbreaks
  }
`;

const polygonSimpleStyle = `
	polygonSimpleStyle {
  	symbolStyle ${symbolStyle} 
  } 
`;

const polygonCategoryStyle = `
  polygonCategoryStyle {
    colorRamp
    symbolStyle ${symbolStyle}
    valueField
    symbolValues ${symbolValue}
  }
`;

const polygonQuantityStyle = `
polygonQuantityStyle {
  valueField
  classNumber
  legendType
  colorRamp
  classification
  symbolStyle ${symbolStyle}
  symbolValues ${symbolValue}
  classbreaks
}
`;
const bubbleStyle = `
  bubbleStyle {
    valueField
    classNumber
    classification
    radius {
      min
      max
    }
    symbolStyle ${symbolStyle}
    symbolValues ${symbolValue}
  }
`;

const pointSimpleStyle = `
  pointSimpleStyle {
    symbolStyle ${symbolStyle}
  }
`;

const pointCategoryStyle = `
  pointCategoryStyle {
    colorRamp
    symbolStyle ${symbolStyle}
    valueField
    symbolValues ${symbolValue}
  }
`;

const pointQuantityStyle = `
  pointQuantityStyle {
    valueField
    classNumber
    legendType
    colorRamp
    classification
    symbolStyle ${symbolStyle}
    symbolValues ${symbolValue}
    classbreaks
  }
`;

const heatMapStyle = `
  heatMapStyle {
    basic {
      radius
      opacity
    }
  }
`;

const query = `
	query GetLayer($id: ID!) {
		getLayer(id: $id) {
			id
			name
			description
			datasetId
			layerGroupId
      dataType
			styleType
      ${lineSimpleStyle}
      ${lineCategoryStyle}
      ${lineQuantityStyle}
      ${polygonSimpleStyle}
      ${polygonCategoryStyle}
      ${polygonQuantityStyle}
      ${pointSimpleStyle}
      ${bubbleStyle}
      ${pointCategoryStyle}
      ${pointQuantityStyle}
      ${heatMapStyle}
		}
	}
`;

export const getLayer = async (id: string) => {
	const { getLayer: result } = await graphierQL<Query, QuerygetLayerArgs>({
		query,
		variables: {
			id,
		},
	});
	return result;
};
