import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import { FormikProps } from "formik";
import * as yup from "yup";

import {
	ColumnDef,
	DataTable,
} from "~ui-components/components/atoms/data-table";
import { Group } from "~ui-components/types/group";
import { useGroupsSelection } from "~ui-components/contexts/group-selection";
import { ButtonCancel } from "~ui-components/components/atoms/button-cancel";

export const UserCreateSchema = yup.object({
	name: yup.string().required("Required"),
	email: yup.string().required("Required"),
	role: yup.string().required("Required"),
	groups: yup.array().required("Required"),
	inviteType: yup.string().required("Required"),
});

export type UserCreateFormValues = yup.InferType<typeof UserCreateSchema>;

export interface UserCreateFormProps {
	form: FormikProps<UserCreateFormValues>;
	rows: Group[];
}

const columns = [
	{
		formatter: (row): React.JSX.Element => (
			<div>
				<Typography
					color="text.primary"
					sx={{ whiteSpace: "nowrap" }}
					variant="subtitle2">
					{row.name}
				</Typography>
			</div>
		),
		name: "Select all",
	},
] as ColumnDef<Group>[];

export function UserCreateForm(props: UserCreateFormProps): React.JSX.Element {
	const { form, rows } = props;

	const { deselectAll, deselectOne, selectAll, selectOne, selected } =
		useGroupsSelection();

	React.useEffect(() => {
		form.setFieldValue("groups", Array.from(selected));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	return (
		<form onSubmit={form.handleSubmit}>
			<Card>
				<CardContent>
					<Stack
						divider={<Divider />}
						spacing={4}>
						<Stack spacing={3}>
							<Typography variant="h6">Group information</Typography>
							<Grid
								container
								spacing={3}>
								<Grid
									xs={12}
									md={6}>
									<FormControl
										fullWidth
										error={!!form.errors.name}>
										<InputLabel>Name</InputLabel>
										<OutlinedInput {...form.getFieldProps("name")} />
										{form.errors.name && (
											<FormHelperText>{form.errors.name}</FormHelperText>
										)}
									</FormControl>
								</Grid>
								<Grid
									xs={12}
									md={6}>
									<FormControl
										fullWidth
										error={!!form.errors.email}>
										<InputLabel>Email address</InputLabel>
										<OutlinedInput {...form.getFieldProps("email")} />
										{form.errors.email && (
											<FormHelperText>{form.errors.email}</FormHelperText>
										)}
									</FormControl>
								</Grid>
								<Grid xs={12}>
									<FormControl
										fullWidth
										error={!!form.errors.role}>
										<Stack
											component={RadioGroup}
											spacing={3}
											{...form.getFieldProps("role")}>
											<FormControlLabel
												control={<Radio />}
												label="Admin"
												value="admin"
											/>
											<FormControlLabel
												control={<Radio size="medium" />}
												label="Editor"
												value="editor"
											/>
											<FormControlLabel
												control={<Radio size="medium" />}
												label="Viewer"
												value="viewer"
											/>
										</Stack>
									</FormControl>
								</Grid>
							</Grid>
						</Stack>
						<Stack spacing={3}>
							<Typography variant="h6">Groups</Typography>
							<Grid
								container
								spacing={3}>
								<Grid xs={12}>
									<Card>
										<DataTable
											selectable
											uniqueRowId={(row) => row.id}
											selected={new Set(Array.from(form.values.groups || []))}
											columns={columns}
											rows={rows}
											onDeselectAll={deselectAll}
											onDeselectOne={(_, row) => {
												deselectOne(row.id);
											}}
											onSelectAll={selectAll}
											onSelectOne={(_, row) => {
												selectOne(row.id);
											}}></DataTable>
										{!rows.length ? (
											<Box sx={{ p: 3 }}>
												<Typography
													color="text.secondary"
													sx={{ textAlign: "center" }}
													variant="body2">
													No groups found
												</Typography>
											</Box>
										) : null}
									</Card>
								</Grid>
							</Grid>
						</Stack>
						<Stack spacing={3}>
							<Typography variant="h6">Invitation email</Typography>
							<Grid
								container
								spacing={3}>
								<Grid xs={12}>
									<FormControl
										fullWidth
										error={!!form.errors.inviteType}>
										<Stack
											component={RadioGroup}
											spacing={3}
											{...form.getFieldProps("inviteType")}>
											<FormControlLabel
												control={<Radio />}
												label="Send to customer"
												value="send-to-customer"
											/>
											<FormControlLabel
												control={<Radio size="medium" />}
												label="Send to owner account"
												value="send-to-owner"
											/>
										</Stack>
									</FormControl>
								</Grid>
							</Grid>
						</Stack>
					</Stack>
				</CardContent>
				<CardActions sx={{ justifyContent: "flex-end" }}>
					<ButtonCancel />
					<Button
						type="submit"
						variant="contained"
						disabled={!form.isValid}>
						Create user
					</Button>
				</CardActions>
			</Card>
		</form>
	);
}
